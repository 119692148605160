import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  background-color: #111;
  color: #fff;
  min-height: 100vh;
  font-family: 'Nunito', sans-serif;
  padding: 50px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const Content = styled.p`
  font-size: 18px;
  line-height: 1.6;
`;

const ReturnPolicy = () => {
    return (
        <PageContainer>
            <Title>Return Policy</Title>
            <Content>
                This is where you can add your return policy. Make sure it's clear and concise for easy understanding.
            </Content>
        </PageContainer>
    );
};

export default ReturnPolicy;
