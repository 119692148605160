import React, { useState } from 'react';
import styled from 'styled-components';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PageContainer = styled.div`
  background-color: #111;
  color: #fff;
  min-height: 100vh;
  font-family: 'Nunito', sans-serif;
  padding: 50px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const Content = styled.p`
  font-size: 18px;
  line-height: 1.6;
`;

const PdfContainer = styled.div`
  background-color: #fff;
  color: #000;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
`;

const PrivacyPolicy = () => {
  const [pdfUrl] = useState(''); // Replace with your actual PDF file URL

  return (
    <PageContainer>
      <Title>Privacy Policy</Title>
      <Content>
        This is where your privacy policy details will go. Keep it minimal and to the point, explaining how you manage data.
      </Content>
      <PdfContainer>
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js`}>
          <Viewer fileUrl={pdfUrl} />
        </Worker>
      </PdfContainer>
    </PageContainer>
  );
};

export default PrivacyPolicy;
